import { TboxRecord } from '@36node-mekong/sdk-ts';
import { Drawer } from 'antd';

export function TboxRecordDrawer(props: { record: TboxRecord; onClose?: () => void }) {
  const { record, onClose = () => {} } = props;

  return (
    <Drawer title={'原始报文'} width={600} open bodyStyle={{ paddingBottom: 80 }} onClose={onClose}>
      <pre>{JSON.stringify(record.body, null, 2)}</pre>
    </Drawer>
  );
}
