import { FaultCategory, FaultRecord, ListDeviceFaultRecordsRequest } from '@36node-mekong/sdk-ts';
import { Card, DatePicker, Input, Spin } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { SearchForm, SearchFormItem } from 'src/components/antd/search-form';
import { NamespaceProvider, WarningSelect, WT } from 'src/features';
import { Page } from 'src/layouts';
import { ymdhms } from 'src/lib/lang/time';
import { useSlice } from 'src/lib/redux-toolkit';

import { listTboxWarningSlice } from './slice';

type SearchValues = ListDeviceFaultRecordsRequest & {
  brokenAtRange?: [Moment, Moment];
};

const columns: AdColumnsType<FaultRecord> = [
  {
    title: '车辆 VIN',
    dataIndex: 'vin',
  },
  {
    title: '车牌号',
    dataIndex: 'plate',
  },
  {
    title: '公司',
    dataIndex: 'ns',
    render: (val) => (
      <NamespaceProvider id={val}>
        {(namespace) => <Spin spinning={!namespace}>{namespace?.name}</Spin>}
      </NamespaceProvider>
    ),
  },
  {
    title: '所属厂商',
    dataIndex: ['device', 'manufacturer'],
  },
  {
    title: '异常类型',
    dataIndex: 'name',
  },
  {
    title: '异常内容',
    dataIndex: 'description',
  },
  {
    title: '报警时间',
    dataIndex: 'brokenAt',
    compute: ymdhms,
  },
];

const showTime = {
  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
};

const searchItems: SearchFormItem[] = [
  {
    label: '车辆 VIN',
    name: 'vin',
    inputComponent: <Input placeholder="请输入车辆 VIN" allowClear />,
  },
  {
    label: '异常类型',
    name: 'code',
    inputComponent: <WarningSelect placeholder="请选择异常类型" allowClear type={WT.TBOX} />,
  },
  {
    label: '报警发生时间',
    name: 'brokenAtRange',
    inputComponent: <DatePicker.RangePicker showTime={showTime} placeholder={['开始时间', '结束时间']} />,
  },
];

const defaultQuery = {
  _limit: 10,
  _offset: 0,
  _sort: '-brokenAt',
  category: FaultCategory.WARNING,
  type: WT.TBOX,
};

function TboxWarningListPage() {
  const dispatch = useDispatch();
  const [listDeviceFaultsState, listDeviceFaults] = useSlice(listTboxWarningSlice);
  const { loading, total, args, result: deviceFaults } = listDeviceFaultsState;
  const { _limit = 10, _offset = 0 } = args as ListDeviceFaultRecordsRequest;

  const handleRefresh = () => dispatch(listDeviceFaults.refresh());

  const handleSearch = (values: SearchValues) => {
    const { brokenAtRange, ...query } = values;

    if (brokenAtRange) {
      query.brokenAt_gte = brokenAtRange[0].toISOString();
      query.brokenAt_lte = brokenAtRange[1].toISOString();
    }
    if (brokenAtRange === null) {
      query.brokenAt_gte = undefined;
      query.brokenAt_lte = undefined;
    }

    dispatch(
      listDeviceFaults.request({
        ...args, // 上一次留存的查询条件
        ...defaultQuery, // 默认查询条件
        ...query, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
      })
    );
  };

  useEffect(() => {
    dispatch(listDeviceFaults.request(defaultQuery));
  }, []); // eslint-disable-line

  return (
    <Page>
      <Card>
        <SearchForm<SearchValues> searchItems={searchItems} onSearch={handleSearch} loading={loading} />
      </Card>

      <AdTable
        columns={columns}
        tableName="TBox 异常"
        wrapperStyle={{ marginTop: 12 }}
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={deviceFaults}
        pagination={{
          total,
          current: _offset / _limit + 1 || 1,
          pageSize: _limit,
        }}
        onRefresh={handleRefresh}
        onChange={handleSearch}
      />
    </Page>
  );
}

export default TboxWarningListPage;
