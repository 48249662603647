import {
  AccountBookOutlined,
  BarChartOutlined,
  FormOutlined,
  GoldOutlined,
  HomeOutlined,
  InteractionOutlined,
  PayCircleOutlined,
  SafetyOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { lazy } from 'react';

import TboxRecordListPage from 'src/containers/company/tbox-record-list.page';
import TboxWarningListPage from 'src/containers/company/tbox-warning-list.page';
import { RequireAuth } from 'src/features';
import { Main } from 'src/layouts/main';
import { RoutePathDefinition } from 'src/lib/routing';

const LoginPage = lazy(() => import('src/containers/session/login'));

const OverviewPage = lazy(() => import('src/containers/overview/overview.page'));

const AbnormalOrderListPage = lazy(() => import('src/containers/order/abnormal-order-list.page'));
const BillDetailPage = lazy(() => import('src/containers/order/bill-detail.page'));
const BillManagement = lazy(() => import('src/containers/order/bill-list.page'));
const BillSettlement = lazy(() => import('src/containers/order/bill-checkout.page'));
const OrderListPage = lazy(() => import('src/containers/order/order-list.page'));

const BatteryAlertListPage = lazy(() => import('src/containers/security/battery-alert-list.page'));
const DeviceFaultRecordListPage = lazy(() => import('src/containers/security/device-fault-record-list.page'));
const BatteryWarningListPage = lazy(() => import('src/containers/security/battery-warning-list.page'));
const DeviceWarningRecordListPage = lazy(() => import('src/containers/security/device-warning-record-list.page'));
const MonitorPage = lazy(() => import('src/containers/security/monitor.page'));
const WorkOrderListPage = lazy(() => import('src/containers/security/work-order-list.page'));

const BatteryHealthDetailPage = lazy(() => import('src/containers/battery/battery-health-detail.page'));
const BatteryHealthPage = lazy(() => import('src/containers/battery/battery-health.page'));
const BatteryListPage = lazy(() => import('src/containers/battery/battery-list.page'));
const BboxRecordListPage = lazy(() => import('src/containers/battery/bbox-record-list.page'));
const BboxWarningListPage = lazy(() => import('src/containers/battery/bbox-warning-list.page'));

const ChargeRecordListPage = lazy(() => import('src/containers/station/charge-record-list.page'));
const OnsiteMonitorPage = lazy(() => import('src/containers/station/onsite-monitor.page'));
const ReservationPage = lazy(() => import('src/containers/station/reservation-list.page'));
const StaffShiftPage = lazy(() => import('src/containers/station/staff-shift.page'));
const StationListPage = lazy(() => import('src/containers/station/station-list.page'));
const StationPage = lazy(() => import('src/containers/station/station.page'));
const SwapRecordListPage = lazy(() => import('src/containers/station/swap-record-list.page'));

const CompanyListPage = lazy(() => import('src/containers/company/company-list.page'));
const DriverListPage = lazy(() => import('src/containers/company/driver-list.page'));
const VehicleListPage = lazy(() => import('src/containers/company/vehicle-list.page'));

const CompanyReportPage = lazy(() => import('src/containers/por/company-report.page'));
const RegionReportPage = lazy(() => import('src/containers/por/region-report.page'));
const StationReportPage = lazy(() => import('src/containers/por/station-report.page'));
const OrderAnalysisPage = lazy(() => import('src/containers/por/order'));
const WastageStatPage = lazy(() => import('src/containers/por/wastage-stat.page'));

const MaintenanceOrderListPage = lazy(() => import('src/containers/maintenance/maintenance-order-list.page'));
const MaintenanceOrderPage = lazy(() => import('src/containers/maintenance/maintenance-order.page'));
const MaintenanceCompanyListPage = lazy(() => import('src/containers/maintenance/company-list.page'));
const MaintenanceMaintainerListPage = lazy(() => import('src/containers/maintenance/maintainer-list.page'));
const TemplateListPage = lazy(() => import('src/containers/maintenance/template-list.page'));
const TemplatePage = lazy(() => import('src/containers/maintenance/template.page'));

const RateSettingPage = lazy(() => import('src/containers/rate/rate-setting.page'));
const RateTemplateListPage = lazy(() => import('src/containers/rate/rate-template-list.page'));

const OperationLogListPage = lazy(() => import('src/containers/system/operation-log-list.page'));
const OrgStructurePage = lazy(() => import('src/containers/system/organization-structure.page'));
// const RuleSettingPage = lazy(() => import('src/containers/system/rule-setting.page'));
const RoleManagementPage = lazy(() => import('src/containers/system/role-management.page'));

function wrapPrivilege(routes: RoutePathDefinition[], prefix = ''): RoutePathDefinition[] {
  return routes.map((route) => {
    const privilege = `${prefix}/${route.path}`;
    let { children, ...rest } = route;
    if (route.children) {
      children = wrapPrivilege(route.children, privilege);
    }
    return { ...rest, children, privileges: [privilege] };
  });
}

export const navRoutes: RoutePathDefinition[] = wrapPrivilege([
  { title: '服务概览', path: 'overview', icon: <HomeOutlined />, element: <OverviewPage /> },
  {
    title: '安全管理',
    path: 'safetyMgmt',
    icon: <SafetyOutlined />,
    children: [
      { title: '实时监控', path: 'realtimeMonitor', element: <MonitorPage /> },
      { title: '电池报警', path: 'batteryAlarm', element: <BatteryAlertListPage /> },
      { title: '换电站报警', path: 'equipFault', element: <DeviceFaultRecordListPage /> },
      { title: '电池预警', path: 'batteryWarning', element: <BatteryWarningListPage /> },
      { title: '换电站预警', path: 'equipWarning', element: <DeviceWarningRecordListPage /> },
      { title: '工单管理', path: 'workorderMgmt', element: <WorkOrderListPage /> },
    ],
  },
  {
    title: '订单管理',
    path: 'orderMgmt',
    icon: <AccountBookOutlined />,
    children: [
      { title: '充换电订单', path: 'swapOrder', element: <OrderListPage /> },
      { title: '换电记录', path: 'swapRecord', element: <SwapRecordListPage /> },
      { title: '充电记录', path: 'chargeRecord', element: <ChargeRecordListPage /> },
      { title: '异常订单管理', path: 'abnormalOrder', element: <AbnormalOrderListPage /> },
      { title: '账单结算', path: 'billStmt', element: <BillSettlement /> },
      { title: '账单管理', path: 'billMgmt', element: <BillManagement /> },
      { title: '账单详情', hidden: true, path: 'BillDetail/:id', element: <BillDetailPage /> },
    ],
  },
  {
    title: '换电站管理',
    path: 'stationMgmt',
    icon: <InteractionOutlined />,
    children: [
      { title: '换电站列表', path: 'stationList', element: <StationListPage /> },
      { title: '换电站详情', path: 'station/:stationNo', hidden: true, element: <StationPage /> },
      // { title: '换电异常', path: 'abnormalSwapRecord', element: <AbnormalSwapRecordListPage /> },
      { title: '人员交接班', path: 'staffShift', element: <StaffShiftPage /> },
      { title: '预约管理', path: 'appointment', element: <ReservationPage /> },
      { title: '现场监控', path: 'onsiteMonitor', element: <OnsiteMonitorPage /> },
    ],
  },
  {
    title: '维保管理',
    path: 'maintenance',
    icon: <FormOutlined />,
    children: [
      { title: '维保模板管理', path: 'template/list', element: <TemplateListPage /> },
      { title: '维保单列表', path: 'work-order/list', element: <MaintenanceOrderListPage /> },
      { title: '维保单详情', path: 'work-order/detail/:workOrderId', element: <MaintenanceOrderPage />, hidden: true },
      { title: '维保公司管理', path: 'company/list', element: <MaintenanceCompanyListPage /> },
      { title: '电池维保员管理', path: 'maintainer/list', element: <MaintenanceMaintainerListPage /> },
    ],
  },
  {
    title: '电池管理',
    path: 'batteryMgmt',
    icon: <GoldOutlined />,
    children: [
      { title: '电池管理', path: 'batteryMgmt', element: <BatteryListPage /> },
      { title: '电池健康状态', path: 'batteryHealth', element: <BatteryHealthPage /> },
      {
        title: '电池健康度明细',
        hidden: true,
        path: 'batteryHealthDetail/:batteryNo',
        element: <BatteryHealthDetailPage />,
      },
      { title: 'BBox数据查询', path: 'bbox-record', element: <BboxRecordListPage /> },
      { title: 'BBox异常', path: 'bbox-warning', element: <BboxWarningListPage /> },
    ],
  },
  {
    title: '公司管理',
    path: 'companyMgmt',
    icon: <TeamOutlined />,
    children: [
      { title: '公司信息管理', path: 'companyList', element: <CompanyListPage /> },
      // { title: '财务管理', hidden: true, path: 'financialMgmt', element: <FinanceListPage /> },
      { title: '车辆管理', path: 'vehicleMgmt', element: <VehicleListPage /> },
      { title: 'TBox报文查询', path: 'tbox-record', element: <TboxRecordListPage /> },
      { title: 'TBox异常', path: 'tbox-warning', element: <TboxWarningListPage /> },
      { title: '司机管理', path: 'driverMgmt', element: <DriverListPage /> },
    ],
  },
  {
    title: '经营报表',
    path: 'por',
    icon: <BarChartOutlined />,
    children: [
      { title: '场站管理报表', path: 'stationReport', element: <StationReportPage /> },
      { title: '企业管理报表', path: 'companyReport', element: <CompanyReportPage /> },
      { title: '区域管理报表', path: 'regionReport', element: <RegionReportPage /> },
      { title: '运营分析数据', path: 'orderAnalysis', element: <OrderAnalysisPage /> },
      { title: '损耗统计', path: 'wastageStat', element: <WastageStatPage /> },
    ],
  },
  {
    title: '费率管理',
    path: 'rateMgmt',
    icon: <PayCircleOutlined />,
    children: [
      { title: '费率模板', path: 'template', element: <RateTemplateListPage /> },
      { title: '费率设置', path: 'setting', element: <RateSettingPage /> },
    ],
  },
  {
    title: '系统设置',
    path: 'systemSetting',
    icon: <SettingOutlined />,
    children: [
      { title: '组织架构管理', path: 'OrgStructure', element: <OrgStructurePage /> },
      { title: '角色管理', path: 'roleManagement', element: <RoleManagementPage /> },
      // { title: '规则设置', path: 'ruleSetting', element: <RuleSettingPage /> },
      // { title: '报警设置', path: 'alarmSetting', element: <AlarmSettingPage /> },
      { title: '操作日志', path: 'operationLogs', element: <OperationLogListPage /> },
    ],
  },
]);

export const staticRoutes: RoutePathDefinition[] = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <Main />
      </RequireAuth>
    ),
    children: navRoutes,
  },
  {
    title: '维保模板编辑',
    path: '/maintenance/template/editor',
    element: <TemplatePage />,
  },
  {
    title: '登录',
    path: '/login',
    element: <LoginPage />,
  },
  { title: '禁止访问', path: '/403', element: <div>403 无权访问</div> }, // 实现一个 403 页面
];
