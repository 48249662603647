import {
  AggregateOrdersRequest,
  Company,
  DeleteBillRequest,
  ListVehiclesRequest,
  MekongAPIClient,
  OrderAggregateResult,
  UpdateCompanyRequest,
  Vehicle,
  VehicleOnlineStatus,
  VehicleSnapshotGroupField,
} from '@36node-mekong/sdk-ts';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { schema } from 'normalizr';

import * as config from 'src/config';

import { injectToken, paramsSerializer } from './helpers';

/**
 * schemas
 */

export const appointmentSchema = new schema.Entity('Reservations');
export const batteryHealthSchema = new schema.Entity('batteryHealth');
export const batteryHealthStatSchema = new schema.Entity('batteryHealthStats');
export const batterySchema = new schema.Entity('batteries');
export const billSchema = new schema.Entity('bills');
export const chargeRecordSchema = new schema.Entity('chargeRecords');
export const chargerSchema = new schema.Entity('chargers');
export const companySchema = new schema.Entity('companies');
export const companyWithVehicleSchema = new schema.Entity('companiesWithVehicle');
export const deviceFaultSchema = new schema.Entity('deviceFaults');
export const driverSchema = new schema.Entity('drivers');
export const faultRecordSchema = new schema.Entity('faultRecord');
export const maintainerSchema = new schema.Entity('maintainers');
export const orderSchema = new schema.Entity('orders');
export const paperSchema = new schema.Entity('papers');
export const rateTemplateSchema = new schema.Entity('rateTemplates');
export const robotSchema = new schema.Entity('robots');
export const sheetSchema = new schema.Entity('sheets');
export const staffShiftSchema = new schema.Entity('staffShifts');
export const stationSchema = new schema.Entity('stations');
export const stationStateSchema = new schema.Entity('stationStates', {}, { idAttribute: 'no' });
export const swapRecordSchema = new schema.Entity('swapRecords');
export const vehicleSchema = new schema.Entity('vehicles', {}, { idAttribute: 'vin' });
export const workOrderSchema = new schema.Entity('workOrders', {
  sheets: [sheetSchema],
  papers: [paperSchema],
});
export const operationSchema = new schema.Entity('operations');

export const vehicleStateSchema = new schema.Entity('vehicleStates');

export const wastageSchema = new schema.Entity('wastages');

export type VehicleWithTboxAt = Vehicle & { tboxAt?: number };
export type OrderAggregateResultWithMileage = OrderAggregateResult & {
  mileage?: number;
  mileageAtStart?: string;
  mileageAtEnd?: string;
};

export class Mekong extends MekongAPIClient {
  /**
   * 如果有自定义的方法，此处实现
   */

  // 批量修改company
  batchUpdateCompanies = async (reqs: UpdateCompanyRequest[], config?: AxiosRequestConfig) => {
    const resps = await Promise.all(reqs.map((req) => this.updateCompany(req, config)));

    let response: AxiosResponse<Company[]> = resps[0] as any;
    response.data = resps.map((r) => r.data);

    return response;
  };

  batchDeleteBills = async (reqs: DeleteBillRequest[], config?: AxiosRequestConfig) => {
    const resps = await Promise.all(reqs.map((req) => this.deleteBill(req, config)));
    let response: AxiosResponse<void> = resps[0] as any;
    return response;
  };

  listVehicleWithTboxAt = async (reqs: ListVehiclesRequest, config?: AxiosRequestConfig) => {
    const resps = await this.listVehicles(reqs, config);

    const vins = resps.data
      ?.filter((v) => v.vin && v.onlineStatus && v.onlineStatus !== VehicleOnlineStatus.UNKNOWN)
      .map((v) => v.vin);

    const states = await this.listVehicleStates({ vin: vins, _limit: 1000 }, config);

    let response: AxiosResponse<VehicleWithTboxAt[]> = resps as any;
    response.data = resps.data.map((v) => {
      const state = states.data.find((s) => s.vin === v.vin);
      if (state) {
        return {
          ...v,
          tboxAt: state.tboxAt,
        };
      }
      return v;
    });

    return response;
  };

  aggregateOrdersWithMileage = async (reqs: AggregateOrdersRequest, config?: AxiosRequestConfig) => {
    const resps = await this.aggregateOrders(reqs, config);

    const vins = resps.data?.filter((v) => v.vin).map((v) => v.vin);

    const { chargeEndAt_gte, chargeEndAt_lte } = reqs;
    const mileageData = await this.aggregateVehicleSnapshot(
      { _group: [VehicleSnapshotGroupField.VIN], vin: vins, at_gte: chargeEndAt_gte, at_lte: chargeEndAt_lte },
      config
    );

    let response: AxiosResponse<OrderAggregateResultWithMileage[]> = resps as any;
    response.data = resps.data.map((v) => {
      const md = mileageData.data.find((s) => s.vin === v.vin);
      if (md) {
        return {
          ...v,
          mileage: md.mileage,
          ...(md.mileage && { mileageAtStart: md.start, mileageAtEnd: md.end }),
        };
      }
      return v;
    });

    return response;
  };
}

const mekongAxiosInstance = axios.create({ baseURL: config.MEKONG_ENDPOINT, paramsSerializer });
mekongAxiosInstance.interceptors.request.use(injectToken);

export const mekong = new Mekong(mekongAxiosInstance);
