import { ListTboxRecordsRequest, TboxRecord } from '@36node-mekong/sdk-ts';
import { Button, Card, DatePicker, Input, Select, Space, TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { SearchForm, SearchFormItem } from 'src/components/antd/search-form';
import { Page } from 'src/layouts';
import { ymdhms } from 'src/lib/lang/time';
import { useSlice } from 'src/lib/redux-toolkit';

import { listTboxRecordSlice, tboxRecordSlice } from './slice';
import { TboxRecordDrawer } from './tbox-record.drawer';

type RangeValue = [Moment | null, Moment | null] | null;

type SearchValues = ListTboxRecordsRequest & {};

const tboxRecordTypeMap = {
  VEHICLE_LOGIN: '车辆登入',
  REALTIME_REPORT: '实时信息上报',
  REISSUE_REPORT: '补发信息上报',
  VEHICLE_LOGOUT: '车辆登出',
  PLATFORM_LOGIN: '平台登入',
  PLATFORM_LOGOUT: '平台登出',
  HEARTBEAT: '心跳',
  TIME: '车辆校时',
};

const showEventType = (val: string): string => tboxRecordTypeMap[val];

const columns: AdColumnsType<TboxRecord> = [
  {
    title: '车辆 VIN',
    dataIndex: ['vin'],
  },
  {
    title: '报文采集时间',
    dataIndex: ['body', 'at'],
    compute: ymdhms,
  },
  {
    title: '报文上报时间',
    dataIndex: ['receiveAt'],
    compute: ymdhms,
  },
  {
    title: '报文类型',
    dataIndex: ['command'],
    compute: showEventType,
  },
];

const defaultQuery = { limit: 5000 };

function TboxRecordListPage() {
  const dispatch = useDispatch();
  const [{ loading, result }, listRecords] = useSlice(listTboxRecordSlice);
  const [{ tboxRecordInDrawer }, { openTboxRecordDrawer, closeTboxRecordDrawer }] = useSlice(tboxRecordSlice);
  const [date, setDate] = useState<Moment>(null);
  const [times, setTimes] = useState<RangeValue>(null);

  useEffect(() => {
    dispatch(listRecords.clear());
    const end = moment();
    const start = moment().subtract(1, 'hour');
    setTimes([start, end]);
    setDate(end);
  }, []);

  const handleCloseDrawer = () => dispatch(closeTboxRecordDrawer());
  const handleSearch = (values: SearchValues) => {
    dispatch(
      listRecords.request({
        ...defaultQuery,
        ...values,
        text: values.text ? `"${values.text}"` : undefined,
        start: date && times?.[0] && date?.format('YYYY-MM-DD') + ' ' + times?.[0].format('HH:mm'),
        end: date && times?.[1] && date?.format('YYYY-MM-DD') + ' ' + times?.[1].format('HH:mm'),
      })
    );
  };

  const disabledTime = (date: Moment, type: 'start' | 'end') => {
    const startTime = times?.[0];
    if (type === 'end' && startTime) {
      const nextHour = startTime?.clone().add(1, 'hour');
      const hours = Array.from({ length: 24 }, (_, i) => i).filter((i) => i !== nextHour?.hour());
      const minutes = nextHour?.minute();

      return {
        disabledHours: () => hours,
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).filter((i) => i > minutes),
      };
    }

    return {};
  };

  const DataColumns = [
    ...columns,
    {
      title: '报文内容',
      key: 'operators',
      render: (_: any, record: any) => (
        <Button type="link" onClick={() => dispatch(openTboxRecordDrawer(record))} style={{ padding: 0 }}>
          查看
        </Button>
      ),
    },
  ];

  const searchItems: SearchFormItem[] = [
    {
      label: '车辆 VIN',
      name: 'vin',
      inputComponent: <Input placeholder="车辆 VIN" allowClear />,
      required: true,
    },
    {
      label: '报文类型',
      name: 'text',
      inputComponent: (
        <Select placeholder="选择报文类型" allowClear>
          {Object.entries(tboxRecordTypeMap).map(([key, text]) => (
            <Select.Option value={key} key={key}>
              {text}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      label: '报文采集时间',
      name: 'dataAtRange',
      inputComponent: (
        <Space>
          <DatePicker format="YYYY/MM/DD" style={{ width: 140 }} value={date} onChange={(val) => setDate(val)} />

          <TimePicker.RangePicker
            placeholder={['开始', '结束']}
            style={{ width: 150 }}
            format={'HH:mm'}
            value={times}
            onChange={(val) => {
              // 如何结束时间大于开始时间一小时，则把结束时间设置为开始时间加一小时
              // 因为在选择框被清空时选择开始时间时，选择值不会被更改（只有两个值都选择后才会更改），且无单个确认的事件，导致无法判断选择
              if (val?.[1]?.diff(val?.[0], 'hour') > 1) {
                val[1] = val[0]?.clone().add(1, 'hour');
              }
              setTimes(val);
            }}
            disabledTime={disabledTime}
          />
        </Space>
      ),
    },
  ];

  return (
    <Page>
      <Card>
        <SearchForm searchItems={searchItems} onSearch={handleSearch} loading={loading} />
      </Card>
      <AdTable
        columns={DataColumns}
        tableName="TBox报文"
        wrapperStyle={{ marginTop: 12 }}
        rowKey="raw"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        pagination={{
          pageSize: 10,
        }}
        showColumnsFilter={false}
      />
      {tboxRecordInDrawer && <TboxRecordDrawer record={tboxRecordInDrawer} onClose={handleCloseDrawer} />}
    </Page>
  );
}

export default TboxRecordListPage;
