import * as mekong from '@36node-mekong/sdk-ts';
import { all, put, takeLatest } from 'redux-saga/effects';

import { injectNsIntoArgs } from 'src/features';
import { makeApiSlice, makeSlice } from 'src/lib/redux-toolkit';
import { makeXlsxExportSlice } from 'src/lib/redux-toolkit/xlsx-export';
import * as services from 'src/services';

//exports

export const exportWastageSlice = makeXlsxExportSlice('wastage');

//station

export const aggregateTotalOrdersSlice = makeApiSlice('aggregate.order.total', services.mekong.aggregateOrders);
export const aggregatePerNaturaldayOrdersSlice = makeApiSlice(
  'aggregate.order.perNaturalDay',
  services.mekong.aggregateOrders
);
export const aggregateOrdersPerdayOrdersSlice = makeApiSlice(
  'aggregate.orders.perday',
  services.mekong.aggregateOrders
);
export const aggregateSwapRecordsPerdayOrdersSlice = makeApiSlice(
  'aggregate.swapRecords.perday',
  services.mekong.aggregateSwapRecords
);
export const aggregateOrdersPerHourSlice = makeApiSlice('aggregate.orders.perhour', services.mekong.aggregateOrders);
export const aggregateSwapRecordsPerHourSlice = makeApiSlice(
  'aggregate.swapRecords.perhour',
  services.mekong.aggregateSwapRecords
);
export const maxVehiclePerDaySlice = makeApiSlice('aggregate.maxVehicle.perday', services.mekong.aggregateOrders);
export const minVehiclePerDaySlice = makeApiSlice('aggregate.minVehicle.perday', services.mekong.aggregateOrders);
export const aggregateCompanyOrdersSlice = makeApiSlice('aggregate.orders.byCompany', services.mekong.aggregateOrders);
export const aggregateCompanySwapRecordsSlice = makeApiSlice(
  'aggregate.swapRecords.byCompany',
  services.mekong.aggregateSwapRecords
);
export const aggregateTotalWorkOrdersSlice = makeApiSlice('aggregate.workorder', services.mekong.aggregateWorkOrders);

export const aggregateTotalReservationSlice = makeApiSlice(
  'aggregate.reservation',
  services.mekong.aggregateReservations
);

export const aggregateTotalDeviceFaultSlice = makeApiSlice(
  'aggregate.deviceFault.total',
  services.mekong.aggregateFaultRecords
);

export const aggregateTotalDeviceFaultByDaySlice = makeApiSlice(
  'aggregate.deviceFault.byday',
  services.mekong.aggregateFaultRecords
);

export const aggregateTotalDeviceFaultByTypeSlice = makeApiSlice(
  'aggregate.deviceFault.bytype',
  services.mekong.aggregateFaultRecords
);

export const listDeviceFaultsRecoverNumSlice = makeApiSlice(
  'aggregate.deviceFaultsRecoverNum',
  services.mekong.listDeviceFaultRecords,
  {
    schema: [services.deviceFaultSchema],
  }
);

export const listWorkOrdersSlice = makeApiSlice('aggregate.workOrders', services.mekong.listWorkOrders, {
  schema: [services.workOrderSchema],
});

export const listRecoverWorkOrdersSlice = makeApiSlice('aggregate.workOrdersRecover', services.mekong.listWorkOrders, {
  schema: [services.workOrderSchema],
});

export const listStationMaintenanceWorkOrderSlice = makeApiSlice(
  'aggregate.maintenance.list',
  services.mekong.listWorkOrders,
  { schema: [services.workOrderSchema] }
);

export const listStationMaintenanceRecoverWorkOrderSlice = makeApiSlice(
  'aggregate.maintenanceRecover.list',
  services.mekong.listWorkOrders,
  { schema: [services.workOrderSchema] }
);

export const aggStaffShiftSlice = makeApiSlice('aggregate.station.aggStaffShift', services.mekong.aggregateStaffShifts);

//company
export const getCompanyWithCountSlice = makeApiSlice('aggregate.company.getWithCount', services.mekong.getCompany);
export const aggregateCompanyTotalOrderSlice = makeApiSlice(
  'aggregate.company.order.total',
  services.mekong.aggregateOrders
);
export const aggregateCompanyPerNaturaldayOrdersSlice = makeApiSlice(
  'aggregate.company.order.perNaturalDay',
  services.mekong.aggregateOrders
);
export const aggregateCompanyOrdersPerdaySlice = makeApiSlice(
  'aggregate.company.order.perday',
  services.mekong.aggregateOrders
);
export const aggregateCompanySwapRecordsPerdaySlice = makeApiSlice(
  'aggregate.company.swapRecords.perday',
  services.mekong.aggregateSwapRecords
);
export const aggregateCompanyTotalReservationSlice = makeApiSlice(
  'aggregate.company.reservation',
  services.mekong.aggregateReservations
);

export const aggregateCompanyOrdersPerHourSlice = makeApiSlice(
  'aggregate.company.order.perhour',
  services.mekong.aggregateOrders
);
export const aggregateCompanySwapRecordsPerHourSlice = makeApiSlice(
  'aggregate.company.swapRecords.perhour',
  services.mekong.aggregateSwapRecords
);

export const maxCompanyVehiclePerDaySlice = makeApiSlice(
  'aggregate.company.maxVehicle.perday',
  services.mekong.aggregateOrders
);
export const minCompanyVehiclePerDaySlice = makeApiSlice(
  'aggregate.company.minVehicle.perday',
  services.mekong.aggregateOrders
);

export const companyVehicleQuantitySlice = makeApiSlice(
  'aggregate.company.vehicle.quantity',
  services.mekong.aggregateOrders
);

export const aggBatteryHealthRecordSlice = makeApiSlice(
  'aggregate.company.aggBatteryHealthRecord',
  services.mekong.aggregateBatteryHealthRecord
);

export const listWastageSlice = makeApiSlice('wastage.list', services.mekong.listWastages, {
  schema: [services.wastageSchema],
});

export const deleteWastageSlice = makeApiSlice('wastage.delete', services.mekong.deleteWastage, {});

export const createWastageSlice = makeApiSlice('wastage.create', services.mekong.createWastage, {
  schema: services.wastageSchema,
});

export const updateWastageSlice = makeApiSlice('wastage.update', services.mekong.updateWastage, {
  schema: services.wastageSchema,
});

export const listCompanyDeviceFaultsRecoverNumSlice = makeApiSlice(
  'aggregate.company.deviceFaultsRecoverNum',
  services.mekong.listDeviceFaultRecords,
  {
    schema: [services.deviceFaultSchema],
  }
);

export const aggregateCompanyTotalDeviceFaultSlice = makeApiSlice(
  'aggregate.company.deviceFault.total',
  services.mekong.aggregateFaultRecords
);

export const aggregateCompanyTotalDeviceFaultByDaySlice = makeApiSlice(
  'aggregate.company.deviceFault.byday',
  services.mekong.aggregateFaultRecords
);

export const aggregateCompanyTotalDeviceFaultByTypeSlice = makeApiSlice(
  'aggregate.company.deviceFault.bytype',
  services.mekong.aggregateFaultRecords
);

export const listCompanyRecoverWorkOrdersSlice = makeApiSlice(
  'aggregate.company.workOrdersRecover',
  services.mekong.listWorkOrders,
  {
    schema: [services.workOrderSchema],
  }
);

export const listCompanyWorkOrdersSlice = makeApiSlice('aggregate.company.workOrders', services.mekong.listWorkOrders, {
  schema: [services.workOrderSchema],
});

export const listCompanyBatteriesSlice = makeApiSlice('battery.batteries', services.mekong.listBatteries, {
  schema: [services.batterySchema],
  prepare: (args: mekong.ListBatteriesRequest, meta?) => ({
    payload: injectNsIntoArgs(args),
    meta,
  }),
});

//namespace
export const getNamespaceSlice = makeApiSlice('aggregate.region.getNamespace', services.stargate.getNamespace);

export const listRegionCompaniesSlice = makeApiSlice('aggregate.region.listCompanies', services.mekong.listCompanies, {
  prepare: (args: mekong.ListCompaniesRequest, meta?) => ({
    payload: { id_like: services.getSession()?.ns, type: mekong.NamespaceLabel.TRANSPORT_COMPANY, ...args },
    meta,
  }),
});

export const countBatterySlice = makeApiSlice('aggregate.region.countBattery', services.mekong.listBatteries);

export const aggregateRegionPerNaturaldayOrdersSlice = makeApiSlice(
  'aggregate.region.order.perNaturalDay',
  services.mekong.aggregateOrders
);

export const aggregateRegionTotalDeviceFaultSlice = makeApiSlice(
  'aggregate.region.deviceFault.total',
  services.mekong.aggregateFaultRecords
);

export const listRegionDeviceFaultsRecoverNumSlice = makeApiSlice(
  'aggregate.region.deviceFaultsRecoverNum',
  services.mekong.listDeviceFaultRecords,
  {
    schema: [services.deviceFaultSchema],
  }
);
export const aggregateRegionTotalDeviceFaultByDaySlice = makeApiSlice(
  'aggregate.region.deviceFault.byday',
  services.mekong.aggregateFaultRecords
);

export const aggregateRegionPerdayOrdersSlice = makeApiSlice(
  'aggregate.region.order.perday',
  services.mekong.aggregateOrders
);

export const aggregateRegionPerdaySwapRecordsSlice = makeApiSlice(
  'aggregate.region.swapRecords.perday',
  services.mekong.aggregateSwapRecords
);

export const aggregateRegionPerHourOrdersSlice = makeApiSlice(
  'aggregate.region.order.perhour',
  services.mekong.aggregateOrders
);

export const aggregateRegionPerHourSwapRecordsSlice = makeApiSlice(
  'aggregate.region.swapRecords.perhour',
  services.mekong.aggregateSwapRecords
);

export const maxRegionVehiclePerDaySlice = makeApiSlice(
  'aggregate.region.maxVehicle.perday',
  services.mekong.aggregateOrders
);
export const minRegionVehiclePerDaySlice = makeApiSlice(
  'aggregate.region.minVehicle.perday',
  services.mekong.aggregateOrders
);

export const aggregateRegionTotalDeviceFaultByTypeSlice = makeApiSlice(
  'aggregate.company.deviceFault.bytype',
  services.mekong.aggregateFaultRecords
);

export const listRegionRecoverWorkOrdersSlice = makeApiSlice(
  'aggregate.region.workOrdersRecover',
  services.mekong.listWorkOrders,
  {
    schema: [services.workOrderSchema],
  }
);

export const listRegionWorkOrdersSlice = makeApiSlice('aggregate.region.workOrders', services.mekong.listWorkOrders, {
  schema: [services.workOrderSchema],
});

export interface WastageState {
  isShowWastageEditor: boolean;
  editWastage?: mekong.Wastage;
  isComputing: boolean;
}

const initState: WastageState = {
  isShowWastageEditor: false,
  editWastage: undefined,
  isComputing: false,
};

export const wastageSlice = makeSlice('wastage', {
  initState,
  reducers: {
    setIsComputing: (state, { payload }: { payload: boolean }) => {
      state.isComputing = payload;
    },
    openWastageEditor: (state, { payload }: { payload: mekong.Wastage | undefined }) => {
      state.isShowWastageEditor = true;
      state.editWastage = payload;
    },
    closeWastageEditor: (state) => {
      state.isShowWastageEditor = false;
      state.editWastage = undefined;
    },
  },
});

function* postEdit() {
  yield put(listWastageSlice.actions.refresh());
  yield put(wastageSlice.actions.closeWastageEditor());
}

wastageSlice.saga = function* () {
  yield all([
    takeLatest(
      [deleteWastageSlice.actions.success, createWastageSlice.actions.success, updateWastageSlice.actions.success],
      postEdit
    ),
  ]);
};
