import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding: 72px 24px 0 24px;
  width: 100%;

  @page {
    size: A4;
  }

  @media print {
    position: absolute;
    top: 0;
    left: 0;
    zoom: 0.6;
  }
`;

interface PageProps {
  className?: string;
}

export function Page({ className, children }: React.PropsWithChildren<PageProps>) {
  return <PageContainer className={className}>{children}</PageContainer>;
}
