import * as mekong from '@36node-mekong/sdk-ts';
import { TboxRecord } from '@36node-mekong/sdk-ts';
import { trim } from 'lodash';
import { all, put, takeLatest } from 'redux-saga/effects';

import { STARGATE_SCOPE, UserType } from 'src/config';
import { allNamespacesSlice, ImportError, injectNsIntoArgs } from 'src/features';
import { notEmptyString } from 'src/lib/lang/string';
import { makeApiSlice, makeSlice } from 'src/lib/redux-toolkit';
import { makeXlsxExportSlice } from 'src/lib/redux-toolkit/xlsx-export';
import { makeXlsxImportSlice } from 'src/lib/redux-toolkit/xlsx-import';
import * as services from 'src/services';

type VehicleInXlsx = Omit<mekong.Vehicle, 'stations'> & {
  stations: string;
};

export interface CompanyState {
  // 编辑公司和车辆
  isShowCompanyEditor: boolean;
  isShowVehicleEditor: boolean;
  isShowDriverEditor: boolean;
  editCompany?: mekong.Company;
  editVehicle?: mekong.Vehicle;
  editDriver?: mekong.Driver;
}

const initState: CompanyState = {
  // 编辑公司
  isShowCompanyEditor: false,
  // 编辑车辆
  isShowVehicleEditor: false,
  //编辑司机
  isShowDriverEditor: false,

  editVehicle: undefined,
};

export const listDriverSlice = makeApiSlice('driver.list', services.mekong.listDrivers, {
  schema: [services.driverSchema],
  prepare: (args: mekong.ListDriversRequest, meta?) => ({
    payload: { ...args, ns_like: args.ns ? undefined : args.ns_like ? args.ns_like : services.getSession()?.ns },
    meta,
  }),
});

export const createDriverSlice = makeApiSlice('driver.create', services.mekong.createDriver, {
  schema: services.companySchema,
});

export const updateDriverSlice = makeApiSlice('driver.update', services.mekong.updateDriver, {
  schema: services.companySchema,
});

export const deleteDriverSlice = makeApiSlice('driver.delete', services.mekong.deleteDriver, {});

export const listCompaniesSlice = makeApiSlice('company.list', services.mekong.listCompanies, {
  schema: [services.companySchema],
  prepare: (args: mekong.ListCompaniesRequest, meta?) => ({
    payload: { id_like: services.getSession()?.ns, type: mekong.NamespaceLabel.TRANSPORT_COMPANY, ...args },
    meta,
  }),
});

export const createCompanySlice = makeApiSlice('company.create', services.mekong.createCompany, {
  schema: services.companySchema,
});

export const updateCompanySlice = makeApiSlice('company.update', services.mekong.updateCompany, {
  schema: services.companySchema,
});

export const deleteCompanySlice = makeApiSlice('company.delete', services.mekong.deleteCompany, {});

export const listVehiclesSlice = makeApiSlice('company.vehicles', services.mekong.listVehicles, {
  schema: [services.vehicleSchema],
  prepare: (args: mekong.ListVehiclesRequest, meta?) => ({
    payload: injectNsIntoArgs(args),
    meta,
  }),
});

export const listOnlineVehiclesSlice = makeApiSlice('company.vehicleState', services.mekong.aggregateVehicleStates, {});

export const createVehicleSlice = makeApiSlice('company.createVehicle', services.mekong.createVehicle, {
  schema: services.vehicleSchema,
});

export const updateVehicleSlice = makeApiSlice('company.updateVehicle', services.mekong.updateVehicle, {
  schema: services.vehicleSchema,
});

export const deleteVehicleSlice = makeApiSlice('company.deleteVehicle', services.mekong.deleteVehicle, {});

/**
 * exports
 */
export const exportDriverSlice = makeXlsxExportSlice('drivers');
export const exportCompanySlice = makeXlsxExportSlice('companies');
export const exportVehiclesSlice = makeXlsxExportSlice('vehicles');

/**
 * imports
 */
export const importDriverSlice = makeXlsxImportSlice<mekong.Driver, mekong.Driver>('dirvers', {
  validate: async (item, row, supplement = {}) => {
    const { companyMap }: { companyMap: { [key: string]: string } } = supplement;

    const errs: ImportError[] = [];

    if (!companyMap[trim(item.ns)]) {
      errs.push({ row, message: `系统中不存在公司 ${trim(item.ns)}` });
    }

    return errs;
  },
  transform: async (item, supplement = {}) => {
    const { companyMap }: { companyMap: { [key: string]: string } } = supplement;

    return {
      ...item,
      ns: companyMap[trim(item.ns)],
    };
  },
  execUpload: async (body) => {
    const found = await services.stargate.listUsers({ ns_like: STARGATE_SCOPE, phone: body.phone });
    if (found.data.length > 0) {
      const existed = found.data[0];
      if (existed.type !== UserType.DRIVER) {
        throw new Error(`手机号 ${body.phone} 已存在，但不是司机, 他的ns:${existed.ns}`);
      }
      await services.mekong.updateDriver({ driver: existed.id, body });
      return;
    }
    await services.mekong.createDriver({ body });
    return;
  },
});

type ImportVehicle = mekong.Vehicle & {
  stations: string[];
};

export const importVehicleSlice = makeXlsxImportSlice<ImportVehicle, VehicleInXlsx>('vehicles', {
  async validate(xlsxItem, row, supplement = {}) {
    const { companyMap, stationMap }: { companyMap: { [key: string]: string }; stationMap: { [key: string]: string } } =
      supplement;

    const errors = [];

    for (const name of xlsxItem.stations.split(',')) {
      if (!stationMap[trim(name)]) {
        errors.push({ row, message: `系统中不存在换电站${name}` });
      }
    }

    if (!companyMap[trim(xlsxItem.ns)]) {
      errors.push({ row, message: `系统中不存在公司${trim(xlsxItem.ns)}` });
    }

    if (
      notEmptyString(xlsxItem.listingDate) &&
      !/^((\d{4}\/\d{2}\/\d{2}$)|(\d{4}-\d{2}-\d{2})|(\d{4}年\d{2}月\d{2}日))$/.test(xlsxItem.listingDate!)
    ) {
      errors.push({ row, message: `启动时间格式错误` });
    }

    return errors;
  },
  async transform(xlsxItem, supplement = {}) {
    const { companyMap, stationMap }: { companyMap: { [key: string]: string }; stationMap: { [key: string]: string } } =
      supplement;

    const { stations, ns, listingDate, onlineStatus, ...rest } = xlsxItem;

    return {
      ...rest,
      stations: stations.split(',').map((name: string) => stationMap[trim(name)]),
      ns: companyMap[trim(ns)],
      listingDate: notEmptyString(listingDate) ? listingDate!.replace(/年|月|\//g, '-').replace('日', '') : undefined,
    };
  },
  async execUpload(body) {
    await services.mekong.upsertVehicle({ body });
  },
});

export const companySlice = makeSlice('company', {
  initState,
  reducers: {
    closeCompanyEditor: (state) => {
      state.isShowCompanyEditor = false;
      state.editCompany = undefined;
    },
    openCompanyEditor: (state, { payload }: { payload: mekong.Company | undefined }) => {
      state.editCompany = payload;
      state.isShowCompanyEditor = true;
    },
    openVehicleEditor: (state, { payload }: { payload: mekong.Vehicle | undefined }) => {
      state.editVehicle = payload;
      state.isShowVehicleEditor = true;
    },
    closeVehicleEditor: (state) => {
      state.isShowVehicleEditor = false;
      state.editVehicle = undefined;
    },
    openDriverEditor: (state, { payload }: { payload: mekong.Driver | undefined }) => {
      state.editDriver = payload;
      state.isShowDriverEditor = true;
    },
    closeDriverEditor: (state) => {
      state.isShowDriverEditor = false;
      state.editDriver = undefined;
    },
  },
});

function* postEdit() {
  yield put(listCompaniesSlice.actions.refresh());
  yield put(allNamespacesSlice.actions.invalidate());
  yield put(companySlice.actions.closeCompanyEditor());
}

function* refreshVehicleList() {
  yield put(companySlice.actions.closeVehicleEditor());
  yield put(listVehiclesWithTboxAtSlice.actions.refresh());
}

function* refreshDriverList() {
  yield put(companySlice.actions.closeDriverEditor());
  yield put(listDriverSlice.actions.refresh());
}

companySlice.saga = function* () {
  yield all([
    takeLatest(
      [createCompanySlice.actions.success, updateCompanySlice.actions.success, deleteCompanySlice.actions.success],
      postEdit
    ),
    takeLatest(
      [
        createVehicleSlice.actions.success,
        updateVehicleSlice.actions.success,
        deleteVehicleSlice.actions.success,
        importVehicleSlice.actions.close,
      ],
      refreshVehicleList
    ),
    takeLatest(
      [
        createDriverSlice.actions.success,
        updateDriverSlice.actions.success,
        deleteDriverSlice.actions.success,
        importDriverSlice.actions.close,
      ],
      refreshDriverList
    ),
  ]);
};

export const listVehiclesWithTboxAtSlice = makeApiSlice(
  'company.vehiclesWithTboxAt',
  services.mekong.listVehicleWithTboxAt,
  {
    prepare: (args: mekong.ListVehiclesRequest, meta?) => ({
      payload: injectNsIntoArgs(args),
      meta,
    }),
  }
);
/**
 * tbox warning
 */
export const listTboxWarningSlice = makeApiSlice('battery.listTboxWarnings', services.mekong.listDeviceFaultRecords);

/**
 * tbox record
 */
export const listTboxRecordSlice = makeApiSlice('company.listTboxRecords', services.mekong.listTboxRecords);

export interface TboxRecordState {
  tboxRecordInDrawer?: TboxRecord;
}

const tboxRecordInitState: TboxRecordState = {
  tboxRecordInDrawer: undefined,
};

export const tboxRecordSlice = makeSlice('company.tbox', {
  initState: tboxRecordInitState,
  reducers: {
    openTboxRecordDrawer: (state, { payload }: { payload: TboxRecord }) => {
      state.tboxRecordInDrawer = payload;
    },
    closeTboxRecordDrawer: (state) => {
      state.tboxRecordInDrawer = undefined;
    },
  },
});
