import {
  GetBatteryRequest,
  GetStationStateRequest,
  GetVehicleRequest,
  GetVehicleStateRequest,
  ListBatteriesRequest,
  ListSheetsRequest,
  ListStationsQuery,
  ListVehiclesQuery,
  NamespaceLabel,
  StationState,
} from '@36node-mekong/sdk-ts';
import { message } from 'antd';
import { all, delay, takeLeading } from 'redux-saga/effects';

import { sheetLabels, UserType } from 'src/config';
import { isFailure, makeApiSlice, makeSlice } from 'src/lib/redux-toolkit';
import * as services from 'src/services';

import { injectNsIntoArgs } from './session';

/**
 * global slice
 */
type GlobalState = {
  selectedZone?: string;
};

const globalInitState: GlobalState = {};

/**
 * 订单页面
 */
export const globalSlice = makeSlice('global', {
  initState: globalInitState,
  reducers: {
    setSelectedZone: (state, action) => {
      state.selectedZone = action.payload;
    },
    clearSelectedZone: (state) => {
      state.selectedZone = undefined;
    },
  },
});

/**
 * global list of stations
 */
export const allStationsSlice = makeApiSlice('stations', services.mekong.listStations, {
  schema: [services.stationSchema],
  prepare: (args: ListStationsQuery, meta?) => ({
    payload: injectNsIntoArgs(args),
    meta,
  }),
});

/**
 * station by id
 */
export const stationByIdSlice = makeApiSlice('stationById', services.mekong.getStation, {
  schema: services.stationSchema,
  prepare: (args: { station: string }) => ({ payload: args, key: args.station }),
  reducebyKey: true,
});

/**
 * station state by no
 */
export const stationStateByNoSlice = makeApiSlice('stationStateByNo', services.mekong.getStationState, {
  prepare: (args: GetStationStateRequest) => ({ payload: args, key: args.stationNo }),
  reducebyKey: true,
});

/**
 * stations by ns
 */
export const stationByNSSlice = makeApiSlice('stationByNS', services.mekong.listStations, {
  schema: [services.stationSchema],
});

/**
 * global list of batteries
 */
export const allBatterySlice = makeApiSlice('batteries', services.mekong.listBatteries, {
  schema: [services.batterySchema],
  prepare: (args: ListBatteriesRequest, meta?) => ({
    payload: injectNsIntoArgs(args),
    meta,
  }),
});

/**
 * battery by id
 */
export const batteryByIdSlice = makeApiSlice('batteryById', services.mekong.getBattery, {
  schema: services.batterySchema,
  prepare: (args: GetBatteryRequest) => ({ payload: args, key: args.battery }),
  reducebyKey: true,
});

/**
 * global list of vehicles
 */
export const allVehiclesSlice = makeApiSlice('vehicles', services.mekong.listVehicles, {
  schema: [services.vehicleSchema],
  prepare: (args: ListVehiclesQuery, meta?) => ({
    payload: injectNsIntoArgs(args),
    meta,
  }),
});

/**
 * vehicle by id
 */
export const vehicleByIdSlice = makeApiSlice('vehicleById', services.mekong.getVehicle, {
  schema: services.vehicleSchema,
  prepare: (args: GetVehicleRequest) => ({ payload: args, key: args.vehicle }),
  reducebyKey: true,
});

/**
 * vehicle state by batteryNo
 */
export const vehicleStateByBatteryNoSlice = makeApiSlice('vehicleStateById', services.mekong.getVehicleState, {
  prepare: (args: GetVehicleStateRequest) => ({ payload: args, key: args.key }),
  reducebyKey: true,
});

/**
 * Global list of company
 */
export const allCompaniesSlice = makeApiSlice('allCompanies', services.mekong.listCompanies, {
  schema: [services.companySchema],
  prepare: (args: services.ListNamespaceRequest, meta?) => ({
    payload: { id_like: services.getSession()?.ns, ...args },
    meta,
  }),
});

export const allTransportCompaniesSlice = makeApiSlice('allTransportCompanies', services.mekong.listCompanies, {
  schema: [services.companyWithVehicleSchema],
  prepare: (args: services.ListNamespaceRequest, meta?) => ({
    payload: { id_like: services.getSession()?.ns, type: NamespaceLabel.TRANSPORT_COMPANY, ...args },
    meta,
  }),
});

export const allMaintenanceCompaniesSlice = makeApiSlice('allMaintenanceCompanies', services.mekong.listCompanies, {
  schema: [services.companySchema],
  prepare: (args: services.ListNamespaceRequest, meta?) => ({
    payload: { id_like: services.getSession()?.ns, type: NamespaceLabel.MAINTENANCE_COMPANY, ...args },
    meta,
  }),
});

export const companyByIdSlice = makeApiSlice('companyById', services.mekong.getCompany, {
  schema: services.companySchema,
  prepare: (args: { company: string }) => ({ payload: args, key: args.company }),
  reducebyKey: true,
});

/**
 * Global namesapaces
 */
export const allNamespacesSlice = makeApiSlice('namespaces', services.stargate.listNamespaces, {
  schema: [services.namespaceSchema],
  prepare: (args: services.ListNamespaceRequest, meta?) => ({
    payload: { id_like: services.getSession()?.ns, ...args },
    meta,
  }),
});
//labels: 'ZONE',

export const namespaceByIdSlice = makeApiSlice('namespaceById', services.stargate.getNamespace, {
  schema: services.namespaceSchema,
  prepare: (args: { namespace: string }) => ({ payload: args, key: args.namespace }),
  reducebyKey: true,
});

export const allUsersSlice = makeApiSlice('users', services.stargate.listUsers, {
  schema: [services.userSchema],
  prepare: (args: services.ListUserRequest, meta?) => ({
    payload: { type: UserType.SYS, ...args },
    meta,
  }),
});

export const userByIdSlice = makeApiSlice('userById', services.stargate.getUser, {
  schema: services.userSchema,
  prepare: (args: { user: string }) => ({ payload: args, key: args.user }),
  reducebyKey: true,
});

/**
 * Global maintainers
 */
export const allMaintainersSlice = makeApiSlice('maintainers', services.mekong.listMaintainers, {
  schema: [services.userSchema],
});

/**
 * Global roles
 */
export const allRolesSlice = makeApiSlice('roles', services.stargate.listScopeRoles, {
  schema: [services.scopeRoleSchema],
});

/**
 * Global session
 */
export const loginSlice = makeApiSlice('login', services.stargate.login);
export const refreshSlice = makeApiSlice('refresh', services.stargate.refresh);
export const logoutSlice = makeApiSlice('logout', services.stargate.logout);

// TODO: 把 session 放入 redux
export const sessionSlice = makeSlice('session', {
  initState: {},
  reducers: {},
});

function* handleNonAuthorization(action: any) {
  const { payload: error = {} } = action;
  if (!loginSlice.isAction(action) && error.response?.status === 401) {
    message.error('会话过期，请重新登录');
    yield delay(1000);
    window.location.href = '/login';
  } else if (isFailure(action)) {
    // api 的错误处理先放在这里了，虽然不合理
    if (!error.keyValue) {
      message.error(error.message || '请求失败');
    }
  }
}

sessionSlice.saga = function* () {
  // @ts-ignore
  yield all([takeLeading(isFailure, handleNonAuthorization)]);
};

/**
 * Global rateTemplates
 */
export const allRateTemplatesSlice = makeApiSlice('allRateTemplates', services.mekong.listRateTemplates, {
  schema: [services.rateTemplateSchema],
});

export const rateTemplateByIdSlice = makeApiSlice('rateTemplateById', services.mekong.getRateTemplate, {
  schema: services.rateTemplateSchema,
  prepare: (args: { rateTemplate: string }) => ({ payload: args, key: args.rateTemplate }),
  reducebyKey: true,
});

/**
 * Global work orders
 */

export const workOrderByIdSlice = makeApiSlice('workOrderById', services.mekong.getWorkOrder, {
  schema: services.workOrderSchema,
  prepare: (args: { workOrder: string }) => ({ payload: args, key: args.workOrder }),
  reducebyKey: true,
});

export const sheetByIdSlice = makeApiSlice('sheetById', services.mekong.getSheet, {
  schema: services.sheetSchema,
  prepare: (args: { sheet: string }) => ({ payload: args, key: args.sheet }),
  reducebyKey: true,
});

export const allStationSheetTemplatesSlice = makeApiSlice('allStationSheetTemplates', services.mekong.listSheets, {
  schema: [services.sheetSchema],
  prepare: (args: ListSheetsRequest, meta?) => ({
    payload: { labels: sheetLabels.STATION_WORK_ORDER, isTemplate: true, ...args },
    meta,
  }),
});

export const allBatterySheetTemplatesSlice = makeApiSlice('allBatterySheetTemplates', services.mekong.listSheets, {
  schema: [services.sheetSchema],
  prepare: (args: ListSheetsRequest, meta?) => ({
    payload: { labels: sheetLabels.BATTERY_WORK_ORDER, isTemplate: true, ...args },
    meta,
  }),
});

/**
 * Global staff shift
 */

export const staffShiftByIdSlice = makeApiSlice('staffShiftById', services.mekong.getStaffShift, {
  schema: services.staffShiftSchema,
  prepare: (args: { staffShift: string }) => ({ payload: args, key: args.staffShift }),
  reducebyKey: true,
});

/**
 * Station state global mqtt
 */

export interface ChargerMeasure {
  batteryNo: string;
  cabinetNo: string;
  chargerNo: string;
  chgOutputCurr: number;
  chgOutputVolt: number;
  currDemand: number;
  elec: number;
  maxCellTemp: number;
  maxCellTempNo: number;
  maxCellVolt: number;
  maxCellVoltNo: number;
  minCellTemp: number;
  minCellTempNo: number;
  minCellVolt: number;
  minCellVoltNo: number;
  recordAt: string | number;
  remainderChgTime: number;
  soc: number;
  stationNo: string;
  voltDemand: number;
}

interface ChargerMeasureSliceState {
  [key: string]: ChargerMeasure[];
}

const chargerMeasureSliceInitState: ChargerMeasureSliceState = {};

export const chargerMeasureSlice = makeSlice('station.chargerMeasure', {
  initState: chargerMeasureSliceInitState,
  reducers: {
    pushChargerState(state, { payload }: { payload: ChargerMeasure }) {
      const key = `${payload.stationNo}-${payload.chargerNo}`;
      if (!state[key]) {
        state[key] = [];
      }
      if (state[key].length > 100) {
        state[key].shift();
      }
      state[key].push(payload);
    },
  },
});

interface StationStateSliceState {
  [key: string]: StationState;
}

const stationStateSliceInitState: StationStateSliceState = {};

export const stationStateSlice = makeSlice('station.stationState', {
  initState: stationStateSliceInitState,
  reducers: {
    setStationState(state, { payload }: { payload: StationState }) {
      const key = payload.no;
      state[key] = payload;
    },
  },
});
