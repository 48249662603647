import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Form, Grid, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const { useBreakpoint } = Grid;

export interface SearchFormItem<T = any> {
  label: string;
  name: keyof T;
  inputComponent: React.ReactElement;
  placeholder?: string;
  required?: boolean;
}

interface SearchFormProps<FormValues> {
  loading?: boolean;
  searchItems?: SearchFormItem[]; // 搜索栏items
  searchDefaultExpand?: boolean; // 默认搜索是否展开
  initialValues?: FormValues; // 表单默认值
  onSearch?: (values: FormValues) => void;
}

const colLayout = {
  sm: 24,
  md: 12,
  xl: 8,
  xxl: 6,
};

const SearchFormContainer = styled.div`
  margin: 12px 0 -12px 0;
`;

export function SearchForm<FormValues>({
  loading,
  searchItems = [],
  searchDefaultExpand = false,
  onSearch,
  initialValues,
}: SearchFormProps<FormValues>) {
  const [form] = Form.useForm();
  const nsLike = Form.useWatch('ns_like', form);
  const [expand, setExpand] = React.useState(searchDefaultExpand);

  // 理论上应该在具体每一个 页面中调用 Form 的 api
  // 但懒得写了，直接在这里重置了
  useEffect(() => {
    form.resetFields(['ns', 'station']);
  }, [nsLike]);

  // 根据屏幕宽度计算每行显示的items数量
  let itemsPerRow = 1;
  const screens = useBreakpoint();
  if (screens.xxl) itemsPerRow = 4;
  else if (screens.xl) itemsPerRow = 3;
  else if (screens.md) itemsPerRow = 2;
  const showExpand = searchItems.length > itemsPerRow;

  const itemSpan = 24 / itemsPerRow;
  const operatorOffset = showExpand
    ? !expand
      ? 0
      : (itemsPerRow - (searchItems.length % itemsPerRow) - 1) * itemSpan
    : searchItems.length < itemsPerRow
    ? 0
    : (itemsPerRow - 1) * itemSpan;

  return (
    <SearchFormContainer>
      <Form
        layout="horizontal"
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 6 }}
        onFinish={onSearch}
        initialValues={initialValues as any}
      >
        <Row style={{ width: '100%' }} gutter={8}>
          {searchItems?.map((item, index) => {
            if (showExpand && !expand && index !== 0 && index >= itemsPerRow - 1) {
              return null;
            }
            const rules = [];
            if (item.required) {
              rules.push({ required: true, message: `请输入${item.label}` });
            }
            return (
              <Col {...colLayout} key={item.name as string}>
                <Form.Item label={item.label} name={item.name as string} rules={rules}>
                  {item.inputComponent}
                </Form.Item>
              </Col>
            );
          })}
          <Col {...colLayout} offset={operatorOffset}>
            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Space>
                <Button type="primary" htmlType="submit" loading={loading}>
                  查 询
                </Button>

                {showExpand && !expand && (
                  <Button
                    type="link"
                    onClick={() => {
                      setExpand(true);
                    }}
                  >
                    展开 <DownOutlined style={{ transform: 'translateY(-3px)' }} />
                  </Button>
                )}

                {showExpand && expand && (
                  <Button
                    type="link"
                    onClick={() => {
                      setExpand(false);
                    }}
                  >
                    收起 <UpOutlined style={{ transform: 'translateY(-3px)' }} />
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </SearchFormContainer>
  );
}
